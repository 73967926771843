import { Button, Form, Modal } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import { useEffect, useState } from 'react'
import passengerService from 'services/passenger.service'
import { toast } from 'react-toastify'

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  instance?: any;
};

const defaultForm = {
  first_name: '',
  last_name: '',
  dob: '',
  note: '',
}

export default function ToolBanPaxModal({
  show,
  onClose,
  onSuccess,
  instance,
}: Props) {
  const [form, setForm] = useState({ ...defaultForm })
  const [formValidated, setFormValidated] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // Mark the form as not validated when modal's visibility changes
    setFormValidated(false)

    if (show) {
      // Set the form values to the instance values if it exists
      setForm(instance || { ...defaultForm })
    }
  }, [show])

  /**
   * Closes the modal.
   */
  const closeModal = () => {
    // Reset the form values
    setForm({ ...defaultForm })

    // Reset the ref validation states
    setFormValidated(false)

    // Inform the parent component to close the modal
    onClose()
  }

  /**
   * Handle form submission.
   */
  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const formEl = event.currentTarget

    // Validate the form
    if (formEl.checkValidity() !== false) {
      // If valid, upsert banned passenger
      upsertBannedPax(form)

      setTimeout(() => {
        // Reset the form values
        setForm({ ...defaultForm })
      }, 500)
    } else {
      setFormValidated(true)
    }
  }

  /**
   * Upsert banned passenger.
   */
  const upsertBannedPax = async (form: any) => {
    setLoading(true)

    const { error } = instance
      ? await passengerService.updateBannedPax(instance.id, form)
      : await passengerService.banPax(form)

    if (error) {
      const message = instance
        ? 'Failed to update banned passenger.'
        : 'Failed to ban passenger.'

      toast(error.message || message)
      setLoading(false)
      onClose()
      return
    }

    const message = instance
      ? 'Banner passenger updated successfully!'
      : 'Passenger banned successfully!'

    toast(message)
    setLoading(false)

    // Inform the parent component that the submission was successful
    onSuccess()
  }

  return (
    <Modal show={show} onHide={closeModal} keyboard={false}>
      <Modal.Body>
        <Flex alignItems="center" className="py-3 justify-content-center">
          Ban Passenger
        </Flex>
        <Form
          noValidate
          validated={formValidated}
          className="mt-4"
          onSubmit={handleSubmit}
        >
          <Flex direction="column">
            <Form.Group className="mb-3">
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder={'First name'}
                value={form.first_name}
                onChange={({ target }) =>
                  setForm({
                    ...form,
                    first_name: target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder={'Last name'}
                value={form.last_name}
                onChange={({ target }) =>
                  setForm({
                    ...form,
                    last_name: target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type="date"
                required
                placeholder={'Date of birth'}
                value={form.dob}
                max={'9999-12-31'}
                onChange={({ target }) =>
                  setForm({
                    ...form,
                    dob: target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Note</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={'Note'}
                value={form.note}
                onChange={({ target }) =>
                  setForm({
                    ...form,
                    note: target.value,
                  })
                }
              />
            </Form.Group>
          </Flex>
          <Flex alignItems="center" className="justify-content-end gap-2">
            <Button variant="light" onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" variant="primary">
              {loading ? 'Banning...' : 'Ban'}
            </Button>
          </Flex>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
