import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import cn from 'classnames'

import ConfirmationModal from 'components/common/ConfirmationModal'
import ToolBanPaxModal from 'modules/simple-crm/components/tools/ToolBanPaxModal'
import ToolBanPaxNoteModal from 'modules/simple-crm/components/tools/ToolBanPaxNoteModal'
import passengerService from 'services/passenger.service'
import useUtils from 'hooks/useUtils'

const COL_COUNT = 4

export default function ToolBannedPassengers() {
  const [loading, setLoading] = useState<boolean>(false)

  const [list, setList] = useState<any[]>([])
  const { formatDate } = useUtils()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [instance, setInstance] = useState<any>(null)
  const [showFormModal, setShowFormModal] = useState(false)
  const [showNoteFor, setShowNoteFor] = useState(null)

  // Fetch the list of banned passengers on mount
  useEffect(() => {
    getBannedPassengers()
  }, [])

  // Fetch the list of banned passengers
  const getBannedPassengers = async () => {
    setLoading(true)

    const { data }: any = await passengerService.getBanned()

    setList(data)
    setLoading(false)
  }

  /**
   * Handle event when either Add or Edit button is clicked.
   */
  const handleUpsertClick = async (pax: any) => {
    setInstance(pax)
    setShowFormModal(true)
  }

  /**
   * Handle event when the form is successfully submitted.
   */
  const handleSuccess = async () => {
    setShowFormModal(false)
    getBannedPassengers()
  }

  /**
   * Ask for confirmation before unbanning a passenger.
   */
  const handleUnbanClick = async (pax: any) => {
    setInstance(pax)
    setShowConfirmModal(true)
  }

  /**
   * Confirm unbanning a passenger.
   */
  const confirmUnban = async () => {
    setShowConfirmModal(false)

    const { error } = await passengerService.unban(instance.id)

    if (error) {
      toast(error.message || 'Failed to unban passenger.')
      return
    }

    toast('Passenger unbanned successfully.')

    getBannedPassengers()
  }

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleUpsertClick(null)}
        >
          <FontAwesomeIcon icon="plus" className="me-2" />
          Add New
        </button>
      </div>
      <Table responsive striped hover>
        <thead>
          <tr>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Full name</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">DOB</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Activty</h6>
            </th>
            <th scope="col">
              <h6 className="text-700 fw-semibold">Actions</h6>
            </th>
          </tr>
        </thead>
        <tbody className="fs-10">
          <tr>
            {loading && (
              <td colSpan={COL_COUNT} className="text-center">
                <Spinner />
              </td>
            )}
          </tr>
          <tr>
            {!loading && !list.length && (
              <td colSpan={COL_COUNT} className="text-center">
                Empty results
              </td>
            )}
          </tr>
          {list.map((item) => (
            <tr key={item.id} className="text-primary fw-semibold">
              <td>
                {item.first_name} {item.last_name}
              </td>
              <td>{formatDate(item.dob, 'DD MMM YYYY')}</td>
              <td>
                Banned: {formatDate(item.created_at, 'DD MMM YYYY')}
                <br />
                Updated: {formatDate(item.updated_at, 'DD MMM YYYY')}
                {item?.updated_by && ` by ${item?.updated_by?.first_name}`}
              </td>
              <td>
                <FontAwesomeIcon
                  icon={faFileAlt}
                  className={cn(
                    'me-3',
                    item.note
                      ? 'text-warning cursor-pointer'
                      : 'text-muted cursor-not-allowed'
                  )}
                  onClick={() => item.note && setShowNoteFor(item)}
                />
                <FontAwesomeIcon
                  icon="edit"
                  className="text-success cursor-pointer me-3"
                  onClick={() => handleUpsertClick(item)}
                />
                <FontAwesomeIcon
                  icon="trash"
                  className="text-danger cursor-pointer"
                  onClick={() => handleUnbanClick(item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ConfirmationModal
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleConfirm={confirmUnban}
        confirmationText="Do you really want to unban this passenger?"
        confirmButtonText="Delete"
      />
      <ToolBanPaxModal
        instance={instance}
        show={showFormModal}
        onClose={() => setShowFormModal(false)}
        onSuccess={handleSuccess}
      />
      <ToolBanPaxNoteModal
        instance={showNoteFor}
        show={!!showNoteFor}
        onClose={() => setShowNoteFor(null)}
      />
    </div>
  )
}
