import Flex from 'components/common/Flex'
import { Modal } from 'react-bootstrap'

type Props = {
  show: boolean;
  onClose: () => void;
  instance?: any;
};

export default function ToolBanPaxNoteModal({
  show,
  onClose,
  instance,
}: Props) {
  return (
    instance && (
      <Modal show={show} onHide={onClose} keyboard={false}>
        <Modal.Body>
          <Flex alignItems="center" className="py-3 justify-content-center">
            Note for {instance?.first_name} {instance?.last_name}
          </Flex>
          <div>
            <p className="fs-10">{instance?.note}</p>
          </div>
        </Modal.Body>
      </Modal>
    )
  )
}
