import { callExternalApi } from 'services/external-api.service'

const bannedPaxEndpoint = `${process.env.REACT_APP_API_SERVER_URL}/api/crm/banned-passengers`

/**
 * Get the list of banned passengers.
 */
export const getBanned = async () =>
  callExternalApi({
    config: {
      url: bannedPaxEndpoint,
      method: 'GET',
    },
  })

/**
 * Ban a passenger.
 */
export const banPax = async (payload: any) =>
  callExternalApi({
    config: {
      url: bannedPaxEndpoint,
      method: 'POST',

      data: { ...payload },
    },
  })

/**
 * Update a banned passenger.
 */
export const updateBannedPax = async (id: number, payload: any) =>
  callExternalApi({
    config: {
      url: `${bannedPaxEndpoint}/${id}`,
      method: 'PUT',
      data: { ...payload },
    },
  })

/**
 * Unban a passenger.
 */
export const unban = async (id: number) =>
  callExternalApi({
    config: {
      url: `${bannedPaxEndpoint}/${id}`,
      method: 'DELETE',
    },
  })

const passengerService = {
  getBanned,
  banPax,
  updateBannedPax,
  unban,
}

export default passengerService
